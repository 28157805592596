 
export const BaseServiceURLs = {
  WEB_URL: "https://dev.maxwin2000.com/",
  BASE_URL: "https://api-dev.maxwin2000.com/",
  REPORTS_BASE_URL:"https://api-dev.maxwin2000.com/",

  WS_BASE_URL: "api-dev.maxwin2000.com",
  SOCKET_URL: "wss://socket.maxbetone.com",

  PRAGMATIC_CASINO_ID: "ppcse00000006201",
  PRAGMATIC_CASINO_LIVE: "dga.pragmaticplaylive.net",
};

 /*
export const BaseServiceURLs = {
  WEB_URL: "https://www.maxbetone.com/",
  BASE_URL: "https://api.maxbetone.com/",
  REPORTS_BASE_URL:"https://reports.maxbetone.com/",
  WS_BASE_URL: "api.maxbetone.com",
  SOCKET_URL: "wss://socket.maxbetone.com",

  PRAGMATIC_CASINO_ID: "ppcph00000006199",
  PRAGMATIC_CASINO_LIVE: "dga.pragmaticplaylive.net",
};


*/
//DEV DETROIDsapi-dev.maxwin2000.com
export const detroidVirtualGames = [
  {
    provider: "LEAP",
    data: [
      "a57fa019b2a58f1eda38c11e93fa0722",
      "0dc8aeed1c5b7e2462d2f3b36c550af8",
      "8a156407ddbaf7848ea94264517854f9",
      "2aa3128fb55d4332eedcbb8bdcd39fc3",
      "0ecde3e3c597d32ad6ef0f1346c4aecf",
    ],
  },
  {
    provider: "RACING",
    data: [
      "e98a7d090641f9e8e8753278ce6a565c",
      "db44a9dfdcc485b6919d37de45200c37",
    ],
  },
  {
    provider: "VIRTUAL",
    data: [
      "1f77d1656406dc7cef75ca893d8985f3",
      "9ce7bf0549cb47b36fe6333516000a52",
      "f88b592ffbafe7f0f06e181dcbe370a2",
      "d97e095f02a752c40a5f5c590ebbe4f9",
    ],
  },
];

export const detroidGames = [
  {
    provider: "SPRIBE",
    data: ["670bc0debe9a9a9228ac9548f39c0f69"],
  },
  {
    provider: "SUPER_KENO",
    data: [
      "7543792161a90a26e777ec22fcda4af5",
      "e838f9531b67a8cd19e7ed9d3e127e94",
    ],
  },
];



export const fundistGames = [
  {
    provider: "SmartSoft-fundist",
    data: ["2372508","2372514","2338680","2372511","2372505","2372517","2404812","2627360","2730390","2730394","2747157","2763050","2771670","2846200","2483270","2663401","2372607","2663685","2663681"],
  },
  {
    provider: "GameArt-fundist",
    data: [
      "2865896",
      "2730898",
    ],
  },
  {
    provider: "Onlyplay-fundist",
    data: [
      "2513786",
      "2174796",
      "2180648",
      "2180608",
      "2180720",
      "2180620"
    ],
  },
];
